import Register from "./components/Register";
import Login from "./components/Login";
import Home from "./components/Home";
import Layout from "./components/shared/Layout";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";
import RequireAuth from "./components/shared/RequireAuth";
import PersistLogin from "./components/shared/PersistLogin";
import Users from "./components/Users";
import { Routes, Route } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Profile from "./components/Profile";
import RegisterClient from "./components/RegisterClient";
import Invites from "./components/Invites";
import ResetPassword from "./components/ResetPassword";
import ChangePassword from "./components/ChangePassword";
import ResetClientPassword from "./components/ResetClientPassword";
import ChangeClientPassword from "./components/ChangeClientPassword";
import ActiveUser from "./components/shared/ActiveUser";
import InactiveUser from "./components/InactiveUser";
const ROLES = {
  User: "user",
  Affiliate: "affiliate",
  Admin: "admin",
};

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route path="login" element={<Login />} />
          <Route path="registrati" element={<Register />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="inattivo" element={<InactiveUser />} />
          <Route path="attiva">
            <Route path=":code" element={<RegisterClient />} />
          </Route>
          <Route path="password-dimenticata" element={<ResetPassword />} />
          <Route path="reset-password">
            <Route path=":token" element={<ChangePassword />} />
          </Route>
          <Route path="clienti">
            <Route
              path="password-dimenticata"
              element={<ResetClientPassword />}
            />
            <Route path="reset-password">
              <Route path=":token" element={<ChangeClientPassword />} />
            </Route>
          </Route>
          {/* we want to protect these routes */}
          <Route element={<PersistLogin />}>
            <Route element={<ActiveUser />}>
              <Route element={<RequireAuth />}>
                <Route path="/" element={<Home />} />
                <Route path="profilo" element={<Profile />} />
                <Route path="inviti" element={<Invites />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                <Route path="utenti" element={<Users />} />
              </Route>
            </Route>
          </Route>

          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </LocalizationProvider>
  );
}

export default App;
