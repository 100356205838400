import { DataGrid, itIT } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "./NoRowsOverlay";

const Table = ({ data, columns, message }) => {
  return (
    data && (
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={data}
          columns={columns}
          getRowId={(_) => Math.random()}
          rowHeight={40}
          autoPageSize={true}
          slots={{ noRowsOverlay: CustomNoRowsOverlay }}
          slotProps={{ noRowsOverlay: { message } }}
          disableRowSelectionOnClick
        />
      </div>
    )
  );
};

export default Table;
