import {
  Container,
  CssBaseline,
  Card,
  CardContent,
  CircularProgress,
  Backdrop,
  Snackbar,
} from "@mui/material";
import Footer from "./Footer";
export const AuthLayout = ({ children, loading, message }) => {
  return (
    <div
      style={{
        backgroundImage: `url(/business.jpg)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100%",
        minHeight: "100vh",
        position: "absolute",
      }}
    >
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
        <CssBaseline />
        <Card sx={{ minWidth: 275, p: 1 }}>
          <CardContent>
            <div style={{ textAlign: "center" }}>
              <img src="/logo-bussola-star.png" height={80} alt="logo"></img>
            </div>
            {children}
          </CardContent>
        </Card>
      </Container>
      <Footer />
      {loading && (
        <Backdrop open={loading}>
          <CircularProgress color="inherit"></CircularProgress>
        </Backdrop>
      )}
      <Snackbar open={!!message} autoHideDuration={6000} message={message} />
    </div>
  );
};
