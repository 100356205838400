import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Badge, MenuList } from "@mui/material";
import BellIcon from "@mui/icons-material/Notifications";
import Notification from "./Notification";
import { useNavigate, useLocation } from "react-router-dom";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);

  const [refresh, reloadData] = useState(false);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getNotifications = async () => {
      try {
        const response = await axiosPrivate.get("/notifications", {
          signal: controller.signal,
        });
        isMounted && setNotifications(response.data);
      } catch (err) {
        console.error(err);
        if (err.response?.status === 401 || err.response?.status === 403) {
          console.log(err.response.status);
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };
    getNotifications();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [refresh]);

  const handleOpenMenu = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };
  const handleCloseMenu = (ref) => {
    setAnchorElNotifications(null);
  };

  const handleNotificationClick = async (notification) => {
    console.log(notification.id);
    await axiosPrivate.patch(
      "/notifications",
      JSON.stringify({
        notificationId: notification.id,
      })
    );
    reloadData();
    setAnchorElNotifications(null);
    if (notification.message.startsWith("Richiesta attivazione licenza")) {
      navigate("/", { state: { from: location }, replace: true });
    } else if (
      notification.message.startsWith("Richiesta attivazione utenza")
    ) {
      navigate("/utenti", { state: { from: location }, replace: true });
    }
  };

  return (
    <>
      <IconButton aria-label="cart" sx={{ mr: 2 }} onClick={handleOpenMenu}>
        <Badge
          badgeContent={notifications.filter((n) => !n.read).length}
          color="primary"
        >
          <BellIcon />
        </Badge>
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElNotifications}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElNotifications)}
        onClose={handleCloseMenu}
      >
        <MenuList>
          {notifications && notifications.length > 0 ? (
            notifications.map((n) => (
              <Notification
                key={n.id}
                notification={n}
                onClick={(_) => handleNotificationClick(n)}
              />
            ))
          ) : (
            <MenuItem>
              <>Nessuna notifica</>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  );
};

export default Notifications;
