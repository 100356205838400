import { ListItem, ListItemText } from "@mui/material";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/it";
dayjs.extend(relativeTime);

const Notification = ({ notification, onClick }) => {
  return (
    <ListItem
      onClick={onClick}
      alignItems="flex-start"
      secondaryAction={!notification.read ? <Brightness1Icon /> : <></>}
    >
      <ListItemText
        primary={notification.message}
        secondary={dayjs(notification.createdAt).locale("it").fromNow()}
      />
    </ListItem>
  );
};

export default Notification;
