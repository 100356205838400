import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Link } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import axios from "../api/axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Alert } from "@mui/material";
import useInput from "../hooks/useInput";
import useToggle from "../hooks/useToggle";
import { AuthLayout } from "./shared/AuthLayout";
const LOGIN_URL = "/auth";

const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();

  const [user, resetUser, userAttributes] = useInput("user", "");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  useToggle("persist", true);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg(null);
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ username: user, password: pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setLoading(false);
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const profile = response?.data?.profile;
      const isActive = response?.data.isActive;
      setAuth({ profile, roles, accessToken, isActive });
      resetUser();
      setPwd("");
      navigate(from, { replace: true });
    } catch (err) {
      setLoading(false);
      if (!err?.response) {
        setErrMsg("Si è verificato un errore durante il login");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Nome utente o password errati");
      } else {
        setErrMsg("Si è verificato un errore durante il login");
      }
    }
  };

  return (
    <AuthLayout loading={loading} message={errMsg}>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Indirizzo email"
          name="username"
          autoComplete="email"
          ref={userRef}
          {...userAttributes}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(e) => setPwd(e.target.value)}
          value={pwd}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Login
        </Button>
        <Grid container>
          <Grid item xs={12}>
            <Link href="/password-dimenticata" variant="body2">
              Password dimenticata?
            </Link>
          </Grid>
          <Grid item xs>
            <Link href="/registrati" variant="body2">
              Registrati
            </Link>
          </Grid>
        </Grid>
        {errMsg && <Alert severity="error">{errMsg}</Alert>}
      </Box>
    </AuthLayout>
  );
};

export default Login;
