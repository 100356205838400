import {
  Container,
  CssBaseline,
  Card,
  CardContent,
  Box,
  TextField,
  Button,
  Breadcrumbs,
  Link,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { AppLayout } from "./shared/AppLayout";

const Profile = () => {
  const [oldPassword, setOldPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    setValidPwd(newPassword.length >= 8);
    setValidMatch(newPassword === matchPwd);
  }, [newPassword, matchPwd]);

  useEffect(() => {
    setErrMsg(null);
  }, [newPassword, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await axiosPrivate.post(
        "/change-password",
        JSON.stringify({
          oldPassword,
          newPassword,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setLoading(false);
      setErrMsg("Password modificata con successo");
      setOldPassword("");
      setNewPassword("");
      setMatchPwd("");
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (!err?.response) {
        setErrMsg(
          "Si è verificato un problema durante la modifica della password"
        );
      } else if (err.response?.status === 401) {
        setErrMsg("Password attuale errata");
      } else {
        setErrMsg(
          "Si è verificato un problema durante la modifica della password"
        );
      }
    }
  };
  return (
    <AppLayout loading={loading} message={errMsg}>
      <Container sx={{ mt: 2 }} maxWidth="xs">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Typography color="text.primary">Profilo</Typography>
        </Breadcrumbs>
        <CssBaseline />
        <Card sx={{ minWidth: 275, p: 1 }}>
          <CardContent>
            <div style={{ textAlign: "center" }}>
              <h1>Modifica password</h1>
            </div>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="oldPassword"
                label="Password attuale"
                type="password"
                id="oldPassword"
                autoComplete="current-password"
                onChange={(e) => setOldPassword(e.target.value)}
                value={oldPassword}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="Nuova Password"
                type="password"
                id="newPassword"
                error={!validPwd}
                helperText={
                  pwdFocus &&
                  !validPwd &&
                  `La password deve contenere almeno 8 caratteri alfanumerici.`
                }
                autoComplete="current-password"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                aria-invalid={validPwd ? "false" : "true"}
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="matchPwd"
                label="Conferma Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={!validMatch}
                helperText={
                  matchFocus && !validMatch && `Le password non corrispondono`
                }
                onChange={(e) => setMatchPwd(e.target.value)}
                value={matchPwd}
                aria-invalid={validMatch ? "false" : "true"}
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!validPwd || !validMatch}
                sx={{ mt: 3, mb: 2 }}
              >
                Conferma
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default Profile;
