import {
  Container,
  Box,
  CircularProgress,
  Backdrop,
  Snackbar,
} from "@mui/material";
import ResponsiveAppBar from "./Navbar";
import Footer from "./Footer";

export const AppLayout = ({ children, loading, message }) => {
  return (
    <>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 2,
        }}
      >
        <Container maxWidth="xl">{children}</Container>
      </Box>
      <Footer />
      {loading && (
        <Backdrop open={loading}>
          <CircularProgress color="inherit"></CircularProgress>
        </Backdrop>
      )}
      <Snackbar open={!!message} autoHideDuration={6000} message={message} />
    </>
  );
};
