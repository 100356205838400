import { useRef, useState, useEffect } from "react";
import axios from "../api/axios";

import {
  FormControlLabel,
  TextField,
  Box,
  Link,
  Checkbox,
  Typography,
  Grid,
  Button,
  Alert,
} from "@mui/material";
import { AuthLayout } from "./shared/AuthLayout";

const USER_REGEX = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const REGISTER_URL = "/register";

const Register = () => {
  const userRef = useRef();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [user, setUser] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState(null);
  const [success, setSuccess] = useState(false);

  const [tosAccepted, setTosAccepted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidPwd(pwd.length >= 8);
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg(null);
  }, [user, pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const v1 = USER_REGEX.test(user);
    const v2 = pwd.length >= 8;
    if (!v1 || !v2) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      setLoading(true);
      await axios.post(
        REGISTER_URL,
        JSON.stringify({
          username: user,
          password: pwd,
          firstName,
          lastName,
          businessName,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setLoading(false);
      setSuccess(true);
      setUser("");
      setPwd("");
      setMatchPwd("");
      setFirstName("");
      setLastName("");
      setBusinessName("");
    } catch (err) {
      setLoading(false);
      if (!err?.response) {
        setErrMsg("Si è verificato un problema durante la registrazione");
      } else if (err.response?.status === 409) {
        setErrMsg("Indirizzo email già in uso");
      } else {
        setErrMsg("Si è verificato un problema durante la registrazione");
      }
    }
  };

  return (
    <AuthLayout loading={loading} message={errMsg}>
      {!success ? (
        <Grid
          component="form"
          container
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <Grid xs={6} pr={1}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="Nome"
              name="firstName"
              autoComplete="firstName"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
          </Grid>
          <Grid xs={6} pl={1}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Cognome"
              name="lastName"
              autoComplete="lastName"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="businessName"
              label="Ragione Sociale"
              name="businessName"
              autoComplete="businessName"
              onChange={(e) => setBusinessName(e.target.value)}
              value={businessName}
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              label="Indirizzo email"
              name="username"
              autoComplete="email"
              ref={userRef}
              onChange={(e) => setUser(e.target.value)}
              value={user}
              error={!validName}
              helperText={
                userFocus && !validName && "Indirizzo email non valido"
              }
              onFocus={() => setUserFocus(true)}
              onBlur={() => setUserFocus(false)}
            />
          </Grid>
          <Grid xs={6} pr={1}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              error={!validPwd}
              helperText={
                pwdFocus &&
                !validPwd &&
                `La password deve contenere almeno 8 caratteri alfanumerici.`
              }
              autoComplete="current-password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              aria-invalid={validPwd ? "false" : "true"}
              onFocus={() => setPwdFocus(true)}
              onBlur={() => setPwdFocus(false)}
            />
          </Grid>
          <Grid xs={6} pl={1}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="matchPwd"
              label="Conferma Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={!validMatch}
              helperText={
                matchFocus && !validMatch && `Le password non corrispondono`
              }
              onChange={(e) => setMatchPwd(e.target.value)}
              value={matchPwd}
              aria-invalid={validMatch ? "false" : "true"}
              onFocus={() => setMatchFocus(true)}
              onBlur={() => setMatchFocus(false)}
            />
          </Grid>
          <Grid xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={
                !validName || !validPwd || !validMatch || !tosAccepted
                  ? true
                  : false
              }
              sx={{ mt: 3, mb: 2 }}
            >
              Registrati
            </Button>
          </Grid>
          <Grid xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  value="tosAccepted"
                  color="primary"
                  onChange={() => setTosAccepted(!tosAccepted)}
                  checked={tosAccepted}
                  error={!tosAccepted}
                  helperText={
                    !tosAccepted &&
                    `Devi accettare i termini di servizio prima di procedere.`
                  }
                />
              }
              labelStyle={{ zIndex: "3" }}
              label={
                <div>
                  <span>Accetto i </span>
                  <Link
                    href={"https://www.bussolastar.it/termini-e-condizioni/"}
                    target="_blank"
                  >
                    termini di servizio
                  </Link>
                  <span> e la </span>
                  <Link
                    href={
                      "https://www.bussolastar.it/bussolastar/privacy-policy/"
                    }
                    target="_blank"
                  >
                    privacy policy
                  </Link>
                </div>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Link href="/password-dimenticata" variant="body2">
              Password dimenticata?
            </Link>
          </Grid>
          <Grid item xs>
            <Link href="/login" variant="body2">
              Login
            </Link>
          </Grid>
        </Grid>
      ) : (
        // <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

        //   {errMsg && <Alert severity="error">{errMsg}</Alert>}
        // </Box>
        <Box>
          <Typography>
            Gentile utente, la tua richiesta di registrazione è stata ricevuta.
            <br /> Controlla a breve il tuo indirizzo di posta (anche la
            cartella spam) in quanto riceverai una mail di conferma
            dell'attivazione della tua utenza.
          </Typography>
          <Link href="/login" variant="body2">
            Login
          </Link>
        </Box>
      )}
    </AuthLayout>
  );
};

export default Register;
