import { Outlet } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { itIT } from "@mui/x-data-grid";
import { itIT as pickersItIT } from "@mui/x-date-pickers/locales";
import { itIT as coreItIT } from "@mui/material/locale";
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#00b9c2",
    },
  },
  itIT,
  pickersItIT,
  coreItIT,
});
const Layout = () => {
  return (
    <main className="App">
      <ThemeProvider theme={theme}>
        <Outlet />
      </ThemeProvider>
    </main>
  );
};

export default Layout;
