import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  InputLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  Slider,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";

const EditUser = ({ open, close, user, saveUser }) => {
  const [availableLicenses, setAvailableLicenses] = React.useState(
    user?.status === "ACTIVE" ? user?.subscription : 3
  );
  const [active, setActive] = React.useState(
    user?.status === "ACTIVE" || false
  );

  const handleChange = (e) => {
    setAvailableLicenses(e.target.value);
  };

  const handleActiveSwitch = (e) => {
    setActive(e.target.checked);
  };

  const handleClose = async (e) => {
    e.preventDefault();
    user.subscription = availableLicenses;
    user.status = active ? "ACTIVE" : "INACTIVE";
    await saveUser(user);
    close();
  };

  React.useEffect(() => {
    setAvailableLicenses(user?.status === "ACTIVE" ? user?.subscription : 3);
    setActive(user?.status === "ACTIVE" || false);
  }, [user]);

  return (
    <Dialog
      open={open}
      onClose={close}
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
    >
      <DialogTitle>Modifica utente</DialogTitle>
      <DialogContent>
        <InputLabel id="licenses-select-label">
          Licenze attivabili:{" "}
          {availableLicenses === 0 ? "Illimitate" : availableLicenses}
        </InputLabel>
        <Slider
          aria-label="Licenze attivabili"
          value={availableLicenses}
          valueLabelDisplay="auto"
          step={1}
          marks
          min={0}
          max={100}
          onChange={handleChange}
        />
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={active} onChange={handleActiveSwitch} />}
            label="Attivo"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Annulla</Button>
        <Button onClick={handleClose}>Salva</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUser;
