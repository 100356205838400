import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        mt: "auto",
        position: "absolute",
        bottom: 0,
        width: "100vw",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="body2" color="text.secondary" align="center">
          {"Copyright © "}
          <Link color="inherit" href="https://bussolastar.it/" target="_blank">
            Bussolastar
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
          <Link
            color="inherit"
            href="http://www.bussolastar.it/bussolastar/privacy-policy/"
            target="_blank"
          >
            Privacy Policy
          </Link>
          {"\t"}
          {"    "}
          <Link
            color="inherit"
            href="https://www.bussolastar.it/termini-e-condizioni/"
            target="_blank"
          >
            Termini di servizio
          </Link>
          {"    "}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;

// import * as React from "react";
// import CssBaseline from "@mui/material/CssBaseline";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
// import Link from "@mui/material/Link";

// function Copyright() {
//   return (
//     <Typography variant="body2" color="text.secondary">
//       {"Copyright © "}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

// const defaultTheme = createTheme();

// export default function Footer() {
//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           minHeight: "100vh",
//         }}
//       >
//         <CssBaseline />
//         <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
//           <Typography variant="h2" component="h1" gutterBottom>
//             Sticky footer
//           </Typography>
//           <Typography variant="h5" component="h2" gutterBottom>
//             {"Pin a footer to the bottom of the viewport."}
//             {"The footer will move as the main element of the page grows."}
//           </Typography>
//           <Typography variant="body1">Sticky footer placeholder.</Typography>
//         </Container>
//         <Box
//           component="footer"
//           sx={{
//             py: 3,
//             px: 2,
//             mt: "auto",
//             backgroundColor: (theme) =>
//               theme.palette.mode === "light"
//                 ? theme.palette.grey[200]
//                 : theme.palette.grey[800],
//           }}
//         >
//           <Container maxWidth="sm">
//             <Typography variant="body1">
//               My sticky footer can be found here.
//             </Typography>
//             <Copyright />
//           </Container>
//         </Box>
//       </Box>
//     </ThemeProvider>
//   );
// }
