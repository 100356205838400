import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";

import dayjs from "dayjs";
import {
  Stack,
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import {
  DataGrid,
  itIT,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import EditUser from "./EditUser";

import CustomNoRowsOverlay from "./shared/NoRowsOverlay";
import { AppLayout } from "./shared/AppLayout";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [refresh, reloadData] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleClickOpen = (user) => {
    console.log(user);
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveUser = async (user) => {
    if (user) {
      const { id, status, subscription } = user;
      await axiosPrivate.patch(`/users/${id}`, {
        status,
        subscription,
      });
    }
    reloadData();
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        setLoading(true);

        const response = await axiosPrivate.get("/users", {
          signal: controller.signal,
        });
        isMounted && setUsers(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err.response?.status === 401 || err.response?.status === 403) {
          console.log(err.response.status);
          navigate("/login", { state: { from: location }, replace: true });
        } else {
          setMessage("Si è verificato un problema durante il caricamento");
        }
      }
    };
    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [refresh]);

  const columns = [
    {
      field: "updatedAt",
      headerName: "Data ultima modifica",
      width: 160,
      type: "date",
      valueFormatter: (params) =>
        dayjs(params.value).locale("it").format("DD-MM-YYYY"),
    },
    {
      field: "profile",
      headerName: "Nome",
      width: 200,
      valueGetter: (params) =>
        `${params.row.userProfile?.firstName} ${params.row.userProfile?.lastName}`,
    },
    {
      field: "businessName",
      headerName: "Ragione Sociale",
      width: 200,
      valueGetter: (params) => params.row.userProfile.businessName,
    },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "active",
      headerName: "Attivo",
      width: 100,
      type: "boolean",
      valueGetter: (params) => params.row.status === "ACTIVE",
    },
    {
      field: "subscription",
      headerName: "Licenze attivabili",
      type: "text",
      width: 120,
      valueGetter: (params) =>
        params.row.status === "ACTIVE"
          ? params.row.subscription === 0
            ? "Illimitate"
            : params.row.subscription
          : "",
    },
    {
      field: "activatedLicenses",
      headerName: "Licenze attivate",
      type: "text",
      width: 120,
      valueGetter: (params) =>
        params.row.status === "ACTIVE" ? params.row.activatedLicenses : "",
    },
    {
      field: "activationDate",
      headerName: "Data Attivazione",
      width: 160,
      type: "date",
      valueGetter: (params) =>
        params.row.activationDate ? new Date(params.row.activationDate) : "",
    },
    {
      headerName: "Azioni",
      width: 80,
      field: "actions",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <IconButton
              aria-label="Modifica utente"
              onClick={() => handleClickOpen(params.row)}
            >
              <EditIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <AppLayout loading={loading} message={message} sx={{ height: "800px" }}>
      <EditUser
        open={open}
        user={selectedUser}
        close={handleClose}
        saveUser={saveUser}
      />
      <div style={{ height: 500, width: "100%" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Typography color="text.primary">Utenti</Typography>
        </Breadcrumbs>
        <Typography
          component="h1"
          variant="h2"
          align="left"
          color="text.primary"
          gutterBottom
        >
          Gestione Utenti
        </Typography>

        <DataGrid
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={users}
          rowHeight={40}
          columns={columns}
          initialState={{
            pagination: {
              sorting: {
                sortModel: [{ field: "updatedAt", sort: "desc" }],
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          getRowId={(row) => row.id}
          autoPageSize={true}
          disableRowSelectionOnClick
        />
      </div>
    </AppLayout>
  );
};

export default Users;
