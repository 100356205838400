import * as React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  itIT,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import useAuth from "../hooks/useAuth";
import CustomNoRowsOverlay from "./shared/NoRowsOverlay";
import { Button } from "@mui/material";

const getNameFromProfile = (profile = {}) => {
  const { firstName, lastName } = profile || {};
  return `${firstName} ${lastName}`;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
export default function DataTable({ licenses, reactivateUser }) {
  const { auth } = useAuth();
  const isAdmin = auth?.roles?.includes("admin");

  const columns = [
    {
      field: "updatedAt",
      headerName: "Data ultima modifica",
      width: 200,
      type: "date",
      valueFormatter: (params) =>
        dayjs(params.value).locale("it").format("DD-MM-YYYY"),
    },
    {
      field: "activationDate",
      headerName: "Data Attivazione",
      width: 200,
      type: "date",
      valueFormatter: (params) =>
        dayjs(params.value).locale("it").format("DD-MM-YYYY"),
    },
    {
      field: "userName",
      headerName: "Nome utente",
      width: 200,
      valueGetter: (params) => getNameFromProfile(params.row.client),
    },
    {
      field: "businessName",
      headerName: "Ragione Sociale",
      width: 200,
      valueGetter: (params) => params.row.client?.businessName,
    },
    {
      field: "affiliate",
      headerName: "Affiliato",
      width: 200,
      valueGetter: (params) =>
        `${params.row.affiliate?.userProfile?.firstName} ${params.row.affiliate?.userProfile?.lastName} (${params.row.affiliate?.userProfile?.businessName})`,
    },
    {
      field: "active",
      headerName: "Attiva",
      width: 100,
      type: "boolean",
    },
    {
      field: "actions",
      headerName: "Azioni",
      width: 100,

      renderCell: (params) => {
        return params.row.active ? (
          <></>
        ) : (
          <Button
            variant="outlined"
            onClick={() => reactivateUser(params.row.id)}
          >
            Attiva
          </Button>
        );
      },
    },
  ];

  return (
    <div style={{ height: 450, width: "100%" }}>
      <DataGrid
        rows={licenses}
        columns={columns}
        rowHeight={40}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              affiliate: !!isAdmin,
            },
          },
          sorting: {
            sortModel: [{ field: "updatedAt", sort: "desc" }],
          },
        }}
        getRowId={(row) => row.id}
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        autoPageSize={true}
        disableRowSelectionOnClick
      />
    </div>
  );
}
