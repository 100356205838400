import { useRef, useState, useEffect } from "react";

import { useParams } from "react-router";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";

import {
  Link,
  TextField,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
} from "@mui/material";
import { AuthLayout } from "./shared/AuthLayout";

const ACTIVATE_URL = "/invites/activate";

const RegisterClient = () => {
  const navigate = useNavigate();
  const [invite, setInvite] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState(null);
  const [success, setSuccess] = useState(false);
  const params = useParams();
  const [tosAccepted, setTosAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setValidPwd(pwd.length >= 8);
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg(null);
  }, [pwd, matchPwd]);

  useEffect(() => {
    const getInvite = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/invites/${params.code}`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
        setLoading(false);
        setInvite(response.data);
      } catch (err) {
        console.error(err);
        setLoading(false);
        if (!err?.response) {
          console.error("Si è verificato un problema durante la registrazione");
        } else if (err.response?.status === 404) {
          console.error("Codice invalido");
          navigate("/pagina-non-troata", { replace: true });
        } else {
          console.error("Si è verificato un problema durante la registrazione");
          navigate("/pagina-non-trovata", { replace: true });
        }
      }
    };
    getInvite();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const v2 = pwd.length >= 8;
    if (!v2) {
      setErrMsg("La password deve contenere almeno 8 caratteri.");
      return;
    }
    try {
      setLoading(true);
      await axios.post(
        `${ACTIVATE_URL}/${invite.id}`,
        JSON.stringify({
          inviteId: invite.id,
          username: invite.email,
          password: pwd,
          firstName,
          lastName,
          businessName,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setLoading(false);
      setSuccess(true);
    } catch (err) {
      setLoading(false);
      if (!err?.response) {
        setErrMsg("Si è verificato un problema durante la registrazione");
      } else if (err.response?.status === 409) {
        setErrMsg("Indirizzo email già in uso");
      } else {
        setErrMsg("Si è verificato un problema durante la registrazione");
      }
    }
  };

  return (
    invite && (
      <AuthLayout loading={loading} message={errMsg}>
        {success ? (
          <Box>
            <Typography>
              Gentile utente, la tua utenza Bussolastar è ora attiva.
            </Typography>
            <Link href="http://app.bussolastar.it" variant="body2">
              Vai a Bussolastar
            </Link>
          </Box>
        ) : (
          <>
            <div>
              <Typography h3 variant="h6">
                Benvenuto {invite.email}
              </Typography>
              <Typography>
                Sei stato invitato a registrarti a Bussolastar da{" "}
                {invite.affiliate}
              </Typography>
              <Typography>
                Completa le seguenti informazioni per ottenere l'accesso.
              </Typography>
            </div>
            <Grid
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
              container
            >
              <Grid xs={6} sx={{ pr: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  label="Nome"
                  name="firstName"
                  autoComplete="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
              </Grid>
              <Grid xs={6} sx={{ pl: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  label="Cognome"
                  name="lastName"
                  autoComplete="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="businessName"
                  label="Ragione Sociale"
                  name="businessName"
                  autoComplete="businessName"
                  onChange={(e) => setBusinessName(e.target.value)}
                  value={businessName}
                />
              </Grid>
              <Grid xs={6} pr={1}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  error={!validPwd}
                  helperText={
                    pwdFocus &&
                    !validPwd &&
                    `La password deve contenere almeno 8 caratteri alfanumerici.`
                  }
                  autoComplete="current-password"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  aria-invalid={validPwd ? "false" : "true"}
                  onFocus={() => setPwdFocus(true)}
                  onBlur={() => setPwdFocus(false)}
                />
              </Grid>
              <Grid xs={6} pl={1}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="matchPwd"
                  label="Conferma Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={!validMatch}
                  helperText={
                    matchFocus && !validMatch && `Le password non corrispondono`
                  }
                  onChange={(e) => setMatchPwd(e.target.value)}
                  value={matchPwd}
                  aria-invalid={validMatch ? "false" : "true"}
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => setMatchFocus(false)}
                />
              </Grid>
              <Grid xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={
                    !validPwd || !validMatch || !tosAccepted ? true : false
                  }
                  sx={{ mt: 2, mb: 2 }}
                >
                  Registrati
                </Button>
              </Grid>
              <Grid xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="tosAccepted"
                      color="primary"
                      onChange={() => setTosAccepted(!tosAccepted)}
                      checked={tosAccepted}
                      error={!tosAccepted}
                      helperText={
                        !tosAccepted &&
                        `Devi accettare i termini di servizio prima di procedere.`
                      }
                    />
                  }
                  labelStyle={{ zIndex: "3" }}
                  label={
                    <div>
                      <span>Accetto i </span>
                      <Link
                        href={
                          "https://www.bussolastar.it/termini-e-condizioni/"
                        }
                        target="_blank"
                      >
                        termini di servizio
                      </Link>
                      <span> e la </span>
                      <Link
                        href={
                          "https://www.bussolastar.it/bussolastar/privacy-policy/"
                        }
                        target="_blank"
                      >
                        privacy policy
                      </Link>
                    </div>
                  }
                />
              </Grid>
            </Grid>
            {/* <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            ></Box> */}
          </>
        )}
      </AuthLayout>
    )
  );
};

export default RegisterClient;
