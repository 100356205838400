import {
  Typography,
  Stack,
  Button,
  Link,
  Breadcrumbs,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import DataTable from "./DataTable";

import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import ActivateLicense from "./ActivateLicense";
import { AppLayout } from "./shared/AppLayout";

const Home = () => {
  const [licenses, setLicenses] = useState([]);
  const [subscription, setSubscription] = useState(0);
  const [refresh, reloadData] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getLicenses = async () => {
      try {
        setLoading(true);

        const response = await axiosPrivate.get("/licenses", {
          signal: controller.signal,
        });
        isMounted && setLicenses(response.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
        if (err.response?.status === 401 || err.response?.status === 403) {
          console.log(err.response.status);
          navigate("/login", { state: { from: location }, replace: true });
        } else {
          setToastMessage("Si è verificato un problema durante il caricamento");
        }
      }
    };
    const getSubscription = async () => {
      try {
        setLoading(true);
        const response = await axiosPrivate.get("/licenses/available", {
          signal: controller.signal,
        });
        isMounted && setSubscription(response.data.availableLicenses);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
        if (err.response?.status === 401 || err.response?.status === 403) {
          console.log(err.response.status);
          navigate("/login", { state: { from: location }, replace: true });
        } else {
          setToastMessage("Si è verificato un problema durante il caricamento");
        }
      }
    };

    getLicenses();
    getSubscription();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [refresh]);

  const handleNewLicenseClosed = async (email) => {
    console.log("activate");
    await axiosPrivate.post("/invites", JSON.stringify({ email }));
    setOpen(false);
    setToastMessage(`L'utente è stato invitato correttamente.`);
    reloadData();
  };

  const reactivateUser = async (id) => {
    setLoading(true);
    await axiosPrivate.post(`/licenses/${id}`);
    setToastMessage(`L'utente è stato riattivato correttamente.`);
    reloadData();
    setLoading(false);
  };

  return (
    <AppLayout loading={loading} message={toastMessage}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Typography color="text.primary">Licenze</Typography>
      </Breadcrumbs>
      <Typography component="h1" variant="h2" align="left" color="text.primary">
        Gestione Licenze
      </Typography>
      <Stack
        sx={{ pt: 2, pb: 2 }}
        direction="row"
        spacing={2}
        justifyContent="start"
      >
        <Typography variant="h5" align="left" color="text.secondary" paragraph>
          Licenze attivabili: {subscription}
        </Typography>
        <Button
          variant="contained"
          onClick={(_) => setOpen(true)}
          disabled={subscription === 0}
        >
          Attiva nuova licenza
        </Button>
        <Link href="/inviti">Inviti in attesa</Link>
      </Stack>
      <DataTable licenses={licenses} reactivateUser={reactivateUser} />
      <ActivateLicense
        open={open}
        activate={handleNewLicenseClosed}
        cancel={(_) => setOpen(false)}
      />
    </AppLayout>
  );
};

export default Home;
