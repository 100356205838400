import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import useAuth from "../../hooks/useAuth";

const UserMenu = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { auth } = useAuth();
  const navigate = useNavigate();
  const logout = useLogout();
  const signOut = async () => {
    await logout();
    navigate("/login");
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (ref) => {
    setAnchorElUser(null);
  };

  const showUsersLink = auth?.roles?.find((role) => ["admin"].includes(role));

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(auth) {
    const firstName = auth?.profile?.firstName || "";
    const lastName = auth?.profile?.lastName || "";
    const name = `${firstName}  ${lastName}`;
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${firstName[0]}${lastName[0]}`,
    };
  }

  return (
    <>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar {...stringAvatar(auth)} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {showUsersLink && (
          <MenuItem onClick={() => navigate("/utenti")}>
            <Typography textAlign="center">Gestione Utenti</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={() => navigate("/profilo")}>
          <Typography textAlign="center">Profilo</Typography>
        </MenuItem>
        <MenuItem onClick={signOut}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
