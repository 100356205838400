import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get("/refresh", {
      withCredentials: true,
    });
    console.log(response.data);
    setAuth({
      profile: response.data.profile,
      roles: response.data.roles,
      accessToken: response.data.accessToken,
      isActive: response.data.isActive,
    });
    return response.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
