import { AuthLayout } from "./shared/AuthLayout";
import { Typography, Link } from "@mui/material";

const InactiveUser = () => {
  return (
    <AuthLayout>
      <Typography>
        Gentile utente, la tua utenza è al momento inattiva.
        <br /> Invia una mail al nostro contatto per provvedere alla
        riattivazione della sua utenza.
      </Typography>
      <Link href="/login">login</Link>
    </AuthLayout>
  );
};

export default InactiveUser;
