import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { AuthLayout } from "./shared/AuthLayout";
import { TextField, Button, Box, Typography, Link } from "@mui/material";
import axios from "../api/axios";

const USER_REGEX = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const ChangePassword = () => {
  const [user, setUser] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);
  const [success, setSuccess] = useState(false);

  const [errMsg, setErrMsg] = useState(null);
  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [loading, setLoading] = useState(false);

  const params = useParams();

  const userRef = useRef();
  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidPwd(pwd.length >= 8);
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg(null);
  }, [user, pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios.patch(
        `/reset-password/${params.token}`,
        JSON.stringify({
          email: user,
          password: pwd,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setLoading(false);
      setSuccess(true);
      setUser("");
    } catch (err) {
      setLoading(false);
      if (!err?.response) {
        setErrMsg(
          "Si è verificato un problema durante il reset della password"
        );
      } else if (err.response?.status === 409) {
        setErrMsg("Richiesta di reset password scaduta");
      } else {
        setErrMsg(
          "Si è verificato un problema durante il reset della password"
        );
      }
    }
  };

  return (
    <AuthLayout loading={loading} message={errMsg}>
      {success ? (
        <Typography>
          Gentile utente, la tua password è stata aggiornata.
          <br /> Puoi accedere al portale con la tua nuova password dalla pagina
          di <Link href="/login">login</Link>.
        </Typography>
      ) : (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            type="email"
            label="Indirizzo email"
            name="username"
            ref={userRef}
            onChange={(e) => setUser(e.target.value)}
            value={user}
            error={!validName}
            helperText={userFocus && !validName && "Indirizzo email non valido"}
            onFocus={() => setUserFocus(true)}
            onBlur={() => setUserFocus(false)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            error={!validPwd}
            helperText={
              pwdFocus &&
              !validPwd &&
              `La password deve contenere almeno 8 caratteri alfanumerici.`
            }
            autoComplete="current-password"
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
            aria-invalid={validPwd ? "false" : "true"}
            onFocus={() => setPwdFocus(true)}
            onBlur={() => setPwdFocus(false)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="matchPwd"
            label="Conferma Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={!validMatch}
            helperText={
              matchFocus && !validMatch && `Le password non corrispondono`
            }
            onChange={(e) => setMatchPwd(e.target.value)}
            value={matchPwd}
            aria-invalid={validMatch ? "false" : "true"}
            onFocus={() => setMatchFocus(true)}
            onBlur={() => setMatchFocus(false)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!validName || !validPwd || !validMatch ? true : false}
          >
            Reset
          </Button>
        </Box>
      )}
    </AuthLayout>
  );
};

export default ChangePassword;
