import { Typography, Link, Breadcrumbs, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "./shared/Table";
import MailIcon from "@mui/icons-material/Mail";
import { AppLayout } from "./shared/AppLayout";

const Invites = () => {
  const [invites, setInvites] = useState([]);
  const [refresh, reloadData] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getInvites = async () => {
      try {
        setLoading(true);
        const response = await axiosPrivate.get("/invites", {
          signal: controller.signal,
        });
        setLoading(false);
        isMounted && setInvites(response.data);
      } catch (err) {
        setLoading(false);
        if (err.response?.status === 401 || err.response?.status === 403) {
          console.log(err.response.status);
          navigate("/login", { state: { from: location }, replace: true });
        } else {
          setToastMessage("Si è verificato un problema durante il caricamento");
        }
      }
    };

    getInvites();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [refresh]);

  const sendAgain = async (id) => {
    try {
      setLoading(true);
      await axiosPrivate.get(`/invites/push/${id}`);
      setToastMessage("Invito inviato di nuovo.");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setToastMessage("Si è verificato un problema nell'invio della mail.");
    }
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Data Creazione",
      width: 200,
      type: "date",
      valueGetter: (params) => new Date(params.row.createdAt),
    },
    {
      field: "invitedEmail",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Azioni",
      width: 80,

      renderCell: (params) => (
        <IconButton
          aria-label="Invia di nuovo"
          onClick={() => sendAgain(params.row.id)}
        >
          <MailIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <AppLayout loading={loading} message={toastMessage}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link underline="hover" color="inherit" href="/">
          Licenze
        </Link>
        <Typography color="text.primary">Inviti in attesa</Typography>
      </Breadcrumbs>
      <Typography
        component="h1"
        variant="h2"
        align="left"
        color="text.primary"
        gutterBottom
      >
        Licenze in attesa
      </Typography>
      <Table
        data={invites}
        columns={columns}
        message={"Nessun invito in attesa"}
      />
    </AppLayout>
  );
};

export default Invites;
