import { useState } from "react";
import { AuthLayout } from "./shared/AuthLayout";
import { TextField, Button, Box, Typography } from "@mui/material";
import axios from "../api/axios";

const ResetClientPassword = () => {
  const [user, setUser] = useState("");
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios.post(
        "/reset-password/client",
        JSON.stringify({
          email: user,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setLoading(false);
      setSuccess(true);
      setUser("");
    } catch (err) {
      setLoading(false);
      if (!err?.response) {
        setErrMsg("Si è verificato un problema durante la registrazione");
      } else if (err.response?.status === 409) {
        setErrMsg("Indirizzo email già in uso");
      } else {
        setErrMsg("Si è verificato un problema durante la registrazione");
      }
    }
  };

  return (
    <AuthLayout message={errMsg} loading={loading}>
      {success ? (
        <Typography>
          Gentile utente, la tua richiesta è stata ricevuta.
          <br /> Controlla a breve il suo indirizzo di posta (anche la cartella
          spam) in quanto riceverai una mail con le istruzioni per la modifica
          della sua password.
        </Typography>
      ) : (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            type="email"
            label="Indirizzo email"
            name="username"
            autoComplete="email"
            onChange={(e) => setUser(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset
          </Button>
        </Box>
      )}
    </AuthLayout>
  );
};

export default ResetClientPassword;
