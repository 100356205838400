import { Link } from "react-router-dom";
import { AuthLayout } from "./shared/AuthLayout";

const Missing = () => {
  return (
    <AuthLayout>
      <h1>Oops!</h1>
      <p>Pagina non trovata</p>
      <div className="flexGrow">
        <Link to="/">Torna alla home</Link>
      </div>
    </AuthLayout>
  );
};

export default Missing;
